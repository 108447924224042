.cursor-pointer {
	cursor: pointer !important;
}

div.dataTables_wrapper div.dataTables_length select {
	min-width: 70px;
	display: inline-block;
	cursor: pointer;
}

.selectedTr {
	background-color: #e7f1ff !important;
}

.selectedTr td {
	border-color: #fff !important;
}

.fa-1_2x {
	font-size: 1.2em;
}

.fa-1_5x {
	font-size: 1.5em;
}

.reqField {
	font-weight: 600;
	color: #ff902b;
}

label {
	font-weight: 500;
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1050px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.modal-xl {
		max-width: 800px !important;
	}
}

.modal {
	overflow-y: auto !important;
}

.wrapper .aside-container.lowZindex {
	z-index: 111 !important;
}

button:disabled {
	cursor: not-allowed;
}

#deselect-all-table {
	font-size: 140%;
}

#deselect-all-table:hover {
	opacity: 0.7;
	transition: 0.2s;
}

input:disabled,
select:disabled {
	cursor: not-allowed !important;
}

.aside-collapsed .wrapper .aside-container .nav-floating {
	margin-left: 69px !important;
}

.unselectable {
	user-select: none !important;
	-moz-user-select: none !important;
	-khtml-user-select: none !important;
	-webkit-user-select: none !important;
	-o-user-select: none !important;
}

.tag {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	display: inline-block;
	height: auto;
	margin: 0 8px 0 0;
	padding: 0 7px;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	opacity: 1;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.tag-greenblue {
	color: #1d39c4;
	background: #f0f5ff;
	border-color: #adc6ff;
}

.fa-150x {
	font-size: 1.5em;
}

.modal-footer,
.modal-header {
	padding: 0.7rem 1rem !important;
}

.divider {
	display: flex;
	margin: 16px 0;
	color: #656565;
	font-weight: 600;
	font-size: 1.075rem;
	white-space: nowrap;
	text-align: center;
	border-top: 0;
	border-top-color: #0000000f;
	clear: both;
	width: 100%;
	min-width: 100%;
	box-sizing: border-box;
	padding: 0;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.divider:before {
	position: relative;
	top: 50%;
	width: 50%;
	border-top: 1px solid transparent;
	border-top-color: #cfdbe2;
	border-bottom: 0;
	transform: translateY(50%);
	content: "";
}

.divider:after {
	position: relative;
	top: 50%;
	width: 50%;
	border-top: 1px solid transparent;
	border-top-color: #cfdbe2;
	border-bottom: 0;
	transform: translateY(50%);
	content: "";
}

.divider-text-left:before {
	top: 50%;
	width: 7%;
}

.divider-text-left:after {
	top: 50%;
	width: 93%;
}

.divider-text-right:before {
	top: 50%;
	width: 93%;
}

.divider-text-right:after {
	top: 50%;
	width: 7%;
}

.divider-inner-text {
	display: inline-block;
	padding: 0 1em;
}

.line {
	align-items: center;
	margin: 1em -1em;
}

.line:before,
.line:after {
	height: 2px;
	margin: 0 1em;
}

.one-line:before,
.one-line:after {
	background: #f1f1f1;
}

.table.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

#toTopBtn {
	display: none;
	position: fixed;
	bottom: 40px;
	right: 5px;
	z-index: 1000;
	font-size: 10px;
	cursor: pointer;
	padding: 10px 12px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
	border-radius: 50%;
	border: 2px solid #1797be;
	color: #1797be !important;
	background-color: #fff !important;
}

#toTopBtn:hover {
	background-color: #1797be !important;
	color: #fff !important;
	transition: 0.3s;
}

.custom-select:disabled {
	color: #222 !important;
	background-color: #e9ecef;
}

.notification-container {
	margin-top: 60px;
}

li.react-tabs__tab {
	font-weight: 500;
	user-select: none !important;
	-moz-user-select: none !important;
	-khtml-user-select: none !important;
	-webkit-user-select: none !important;
	-o-user-select: none !important;
	padding: 10px 15px;
	font-size: 0.95rem;
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
	width: 100%;
	top: 43px;
}

.react-dropdown-select-content.react-dropdown-select-type-single {
	padding-left: 0.5rem;
}

.rdt_TableHeadRow,
.rdt_TableBody .rdt_TableCell {
	font-size: 0.875rem;
	font-family: "Google Sans" !important;
}

.rdt_TableHeadRow {
	font-weight: 600;
	color: #6a6a6a;
	min-height: 43px !important;
}

.rdt_TableBody .rdt_TableCell {
	font-weight: 400;
	color: #656565;
}

.rdt_TableCell input[type="checkbox"],
.rdt_TableHeadRow input[type="checkbox"] {
	height: 20px;
	width: 20px;
}

.rdt_Pagination {
	font-size: 0.8rem !important;
	color: #656565 !important;
}

.card .card-body header {
	padding: 5px 0 10px;
}

.font-weight-600 {
	font-weight: 600 !important;
}

.tag-magenta {
	color: #c41d7f;
	background: #fff0f6;
	border-color: #ffadd2;
}

.tag-red {
	color: #cf1322;
	background: #fff1f0;
	border-color: #ffa39e;
}

.tag-orange {
	color: #d46b08;
	background: #fff7e6;
	border-color: #ffd591;
}

.tag-green {
	color: #389e0d;
	background: #f6ffed;
	border-color: #b7eb8f;
}

.tag-blue {
	color: #096dd9;
	background: #e6f7ff;
	border-color: #91d5ff;
}

.tag-geekblue {
	color: #1d39c4;
	background: #f0f5ff;
	border-color: #adc6ff;
}

.tag-purple {
	color: #531dab;
	background: #f9f0ff;
	border-color: #d3adf7;
}

.custom-select,
.form-control {
	min-height: 38px !important;
}

.pdf-page {
	background-color: #f1f1f1;
	padding-top: 10px;
	padding-bottom: 10px;
}

.pdf-document {
	max-height: 65vh;
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

/* center the pdf page of react-pdf package */
.react-pdf__Page__canvas {
	margin-left: auto;
	margin-right: auto;
}

.dropzone-container .dropzone {
	border: 2px dashed #bbb;
	padding: 5px 15px;
}

input[type="file"] {
	color: transparent;
}

.inner_select__control {
	cursor: pointer !important;
	border-color: #dde6e9 !important;
}

.inner_select__menu {
	z-index: 10 !important;
}

.inner_select__value-container {
	padding: 0.375rem 1rem !important;
}

.inner_select__input-container {
	margin: 0 !important;
	padding: 0 !important;
}

.inner_select__single-value {
	color: #495057 !important;
}

#parametricsCard .inner_select__menu,
#studentGradesProgressCard .inner_select__menu {
	z-index: 20 !important;
}

#parametricsCard .inner_select__control {
	min-width: 200px !important;
}

.login-select-container {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}

.login-select-container .inner_select__control {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

div.card.student-self-test-card {
	border-top-color: #7266ba;
}

div.self-test-entry-box {
	background-color: #f8f9fa !important;
	border-bottom: 1px solid #dee2e6 !important;
	border-left: 1px solid #dee2e6 !important;
	border-right: 1px solid #dee2e6 !important;
}

div.self-text-entry-box-warning {
	border-top: 5px solid #ff902b;
}

div.self-text-entry-box-purple {
	border-top: 5px solid #7266ba;
}

div.self-text-entry-box-info {
	border-top: 5px solid #23b7e5;
}

div.self-text-entry-box-success {
	border-top: 5px solid #27c24c;
}

div.self-text-entry-box-primary {
	border-top: 5px solid #5d9cec;
}

div.self-text-entry-box-inverse {
	border-top: 5px solid #131e26;
}

div.self-text-entry-box-green {
	border-top: 5px solid #37bc9b;
}

div.self-text-entry-box-danger {
	border-top: 5px solid #f05050;
}

.self-test-countdown {
	font-size: 220%;
}

.pdf-controller-icon {
	font-size: 120%;
}

.pdf-controller-bar {
	background: rgba(34, 34, 34, 0.8);
	padding: 0.75rem 0.6rem;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.select-is-valid .inner_select__control {
	border-color: #28a745 !important;
}

.select-is-invalid .inner_select__control {
	border-color: #dc3545 !important;
}

#self-test-info-card .card-body.hide {
	display: none !important;
}

.lesson-grades-states-select-container {
	width: 100%;
}


.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}
